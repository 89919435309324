import React from "react"
import { Link } from "gatsby"
import CommingSoon from "../components/commingSoon"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <div>
    <SEO title="Contact" />
    <CommingSoon text="Contact "></CommingSoon>
  </div>
)

export default IndexPage
